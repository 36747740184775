jQuery(document).ready(function ($) {

	function refreshRecaptchaToken() {
		grecaptcha.ready(function () {
			grecaptcha.execute(recaptcha.siteKey, {action: "contactform"}).then(function (token) {
				const recaptchaInput = $("input[name=\"recaptcha\"]");
				if (recaptchaInput.length > 0) {
					recaptchaInput.val(token);
				}
			});
		});
	}

	// Refresh the reCAPTCHA token every 30 minutes
	const refreshInterval = 30 * 60 * 1000; // 30 minutes in milliseconds
	setInterval(refreshRecaptchaToken, refreshInterval);

	grecaptcha.ready(function () {
		refreshRecaptchaToken(); // Initial token generation
	});

});